<template>
  <div>
    <el-dialog :title="text+'讲义轮次'"
               :visible.sync="dialogVisible"
               v-dialogDrag
               :close-on-click-modal="false"
               :append-to-body="true"
               width="500px"
               :before-close="handleClose">
      <el-form :model="form"
               :rules="rules"
               :validate-on-rule-change="false"
               ref="formRefs"
               label-width="120px">

        <el-row :gutter="10">
          <el-col :span="24">
            <el-form-item label="轮次名称"
                          prop="lecture_turn_name">
              <el-input clearable
                        placeholder="请输入"
                        v-model="form.lecture_turn_name"
                        style="width:100%" />
            </el-form-item>
          </el-col>
        </el-row>

        <el-row :gutter="10">
          <el-col :span="24">
            <el-form-item label="年级"
                          prop="lecture_grade">
              <el-select style="width:100%"
                         v-model="form.lecture_grade"
                         placeholder="请选择">
                <el-option v-for="item in gradeList"
                           :key="item.grade_id"
                           :label="item.grade_name"
                           :value="item.grade_id">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row :gutter="10">
          <el-col :span="24">
            <el-form-item label="轮次描述">
              <el-input clearable
                        placeholder="请输入"
                        v-model="form.lecture_turn_description"
                        style="width:100%" />
            </el-form-item>
          </el-col>
        </el-row>

      </el-form>
      <span slot="footer"
            class="dialog-footer">
        <el-button type="primary"
                   @click="handleSave">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data () {
    return {
      dialogVisible: false,
      text: '新增',
      form: {},
      gradeList: [],
      rules: {
        lecture_turn_name: [
          { required: true, message: '请输入', trigger: 'blur' },
        ],
        lecture_grade: [
          { required: true, message: '请选择', trigger: 'blur' },
        ]
      },
      treeList: [],
    }
  },
  mounted () {
    this.getGradeList()
  },
  methods: {
    getGradeList () {
      this.$http({
        url: '/api/v1/school/select_grade',
        method: 'get'
      }).then(res => {
        this.gradeList = res.data.list
      })
    },
    handleClose () {
      this.form = {}
      this.$refs.formRefs.resetFields()
      this.dialogVisible = false
    },
    handleSave () {
      let form = JSON.parse(JSON.stringify(this.form))
      this.$refs.formRefs.validate(val => {
        if (!val) return
        this.$http({
          url: '/api/v1/lecture/add_turn',
          method: 'POST',
          data: form
        }).then(res => {
          this.$notify({
            title: '提示',
            message: '成功!',
            type: 'success'
          });
          this.$parent.search()
          this.handleClose()
        })
      })

    },

  }
}
</script>

<style lang="scss" scoped>
::v-deep .el-dialog__header {
  font-size: 20px;
  font-weight: bold;
  color: black;
  text-align: center;
}

::v-deep .el-dialog__headerbtn .el-dialog__close {
  color: white;
  background: #cccccc;
  border-radius: 50%;
  padding: 4px;
}
::v-deep .el-dialog__footer {
  text-align: center;
}
.tree_warp {
  height: 350px;
  overflow-y: scroll;
}

::-webkit-scrollbar {
  display: none; /* Chrome Safari */
}
</style>